

.colnav{
    color:#222;
    margin-bottom: -2px;
}



.opa035{
    opacity: 0.35;
}

.bmc-dash-pill-active {

    border-bottom: 3px solid #0075fe;
        margin-bottom: -2.5px;

}

.bmc-dash-pill {
    padding: 0px 0 15px;
    margin-right: 30px;
}



 
.bmc-dash-pill:hover span, .bmc-dash-pill-active span {
    color: #0d0c22!important;
}

.color-0d0, .color-0D0 {
    color: #0d0c22;
}

.beta-tag {
    padding: 1px 8px;
    line-height: 19px;
    height: 21px;
    font-size: 11px;
    margin-top: 2px;
    margin-left: 10px;
}

.grey-bg-dim {
    background-color: rgba(13,12,34,.05);
}

.rad16{
    border-radius: 16px;
}

.t-0 {
    top: 0!important;
}

.ab-link, .p-b-wrap {
    position: absolute;
    width: 100%;
    left: 0;
    height: 100%;
    cursor: pointer;
    top: 0;
    z-index: 1;
}
.ant-menu-item{
    padding: 0!important;
}

.ant-menu-item a{
    padding-left: 24px!important;
    width: 100%;
}
.ant-menu-item a.selected{
    background-color: rgba(34,34,34,.05);
    height:100%
}

.menuside li{
    border-radius: 8px;
}

.ant-menu-item-only-child {
    padding-left: 60px;
    font-size: 16px;
    margin-bottom: 0px!important;
}

.menuside li:hover{

    background-color: rgba(13,12,34,.04);
    border-radius: 8px;
}


.ant-menu-inline > .ant-menu-item {
    height:45px!important;
}


.ant-menu-inline .ant-menu-item::after {
    border-right: 0!important;
}

.ant-menu-submenu > .ant-menu {

    background-color: transparent!important;

}