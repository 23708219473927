/* style des boutons de hearder , cadre et box*/
.deco{
    background-color: rgb(241, 245, 248);
    padding: 10px!important;
    border-radius: 8px;
    height: 50px!important;
    width: 50px!important;
   
}
.deco2{
    background-color: rgba(17, 66, 226, 0.925);
    padding: 10px!important;
    border-radius: 8px;
    height: 50px!important;
    width: 50px!important;
    margin-left: 15px!important; 
    cursor: pointer;
}
.deco3 {
    background-color: rgb(241, 245, 248);
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 8px;
    height: 40px!important;
    width: 40px!important;
    cursor: pointer;
   
}
.deco4{
    background-color: rgb(235, 235, 235);
    padding: 2px!important;
    /* margin-right: 2%; */
   height: 70px;
}
.deco5{
    background-color: rgb(241, 245, 248);
    padding: 10px!important;
    border-radius: 8px;
    height: 40px!important;
    width: 40px!important;
}
.deco6{
    background-color: rgb(235, 235, 235);
    padding: 1%;
}
.ln-d{
    padding: 1%;
    align-content: center;
}
.pd-r{
    padding: 0%!important;
}
.call{
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    display: block;
    z-index: 999!important;
    width: 100%;  
    
}
.mg-t{
    margin-top: 5%;
    margin-left: 5%;
}
.mgt{
    margin-top: 5%; 
}
.ov-h{
    overflow-y: hidden;
    padding-top: 7em!important;
}
.ml{
    margin-left: 5%;
}
.mlb{
    margin-left: 15px;
}

.col-g-bol{
    color: rgb(206, 203, 203);
   
}
.spa{
    justify-content: space-between;
    margin: 2%;
}
.mt{
  
    margin-block: auto;
}
/* .ani .ant-tabs-tab-wrap{
    background-color: #1e99f7 !important;}
    .ant-tabs-left  .altab {
    justify-content: flex-start;
    padding-left: 8px;
} */
/* modification de la classe antd pour la tabs et l'input*/
.ani  .ant-tabs-nav-wrap {
  
    padding-left: 0px!important;
   
    color: rgb(139, 118, 118) !important;
    justify-content: flex-start!important;
} 

.ani .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #5050507a;
    font-weight: bold!important;
   font-size: 15px!important;
    text-shadow: 0 0 0.25px #8f928f!important;
}
.ani .ant-tabs-tab-active {
    background-color: #1783ff27!important;
    border-radius: 8px!important;
  }
  .ani .ant-tabs-tab:hover {
    background-color: #f1eeee96!important;
    border-radius: 8px!important;
  }
.ani .ant-tabs-tab:hover{
    color: #80adec;
}
.calli{
    height: 100vh;
    overflow-y: scroll; 
}
/* modification de l'input */

.ipt .ant-input {
 
    background-color:white!important;
    height: 70px!important; 
    border-radius: 1px!important;
   
}
.md{
    margin-top: auto!important;
}
.mb{
    margin-bottom: 3%;
}
.dcrow{
    border: rgb(235, 235, 235) solid 2px;
  
}
.dcrow2{
    border: rgb(235, 235, 235) solid 2px;
    padding: 1%;
    margin-bottom: 3%;

}
.colb{
    color: #006aff!important;
    font: 16px!important;
    padding: 50px!important;  
}
.st-not{
    font-size: 18px;
    font-weight: bold!important;
    
    color: black;
   
}
.st-snot{
    color:  gray;
    font-weight: var(--core-type-semibold-weight);
    font-size: 17px;
}
.st-m{
    color: black;
    font-weight: bold!important;
}
.v-space{
    margin-bottom: 25px!important;
    display: flex;
}
.space{
    margin-top: 25px!important;
}
.v-space-rmes{
    margin-bottom: 40px!important; 
}
.form-ms{
    padding: 15px;
    border-bottom: rgb(230, 226, 226) solid 1px!important;
    cursor: pointer;
}
.form-m{
    padding: 15px;
}
.h-space{
    margin-left: 5%!important;
}
.title{
    font-weight: bold;
}
.align{
    padding: 10px!important;
   
}
.ic{
     margin: 10px!important;
    padding: 15px!important;
}
.block-t{
    position: relative;
    padding: 10px!important;
    background-color: rgb(231, 230, 230);
    height: 40px!important;
    width: 5%;
}
.block-t2{
    position: relative;
    padding: 10px!important;
    background-color: rgb(231, 230, 230);
    height: 40px!important;
    width: 5%;
    margin-left: 10%;
}
.desc{
    font-weight: 500;
    color: gray;
    font-size: 15px;
}
.btn-text{
    font-weight: 400;
    color: #006aff;
    size: 25rem;
    padding: 5px;
}
.decs-mes{
    font-weight: 500;
    color: gray;
}
.btn-re-mes{
    background-color: rgb(241, 245, 248);
    padding: 10px!important;
    border-radius: 8px;
    height: 50px!important;
    width: 100%;
    cursor: pointer;

}
.box{
    background-color: rgb(241, 245, 248);
    padding: 5px!important;
    border-radius: 8px;
    height: 50px!important;
    width: 50px!important;
}
.ce{
    padding: 8px!important;
    size: 40px!important;
}


/* hover des different composant */
.hov:hover{
    background-color: rgb(229, 239, 247);
    border-radius: 10px!important;

}
.btn-re-mes:hover{
    background-color: rgb(229, 239, 247);
    cursor: pointer;
}
.deco:hover{
    background-color: rgb(229, 239, 247);
    cursor: pointer;
}
/* fin des hover  */



/* modification sur l'input */
.ip{
    border: none!important; 
  
    border-color: transparent!important;
    width: 100%!important;  
}
.hh:placeholder-shown{
    margin-left: 40px; 
   
}

.ip-mess{
    width: 100%!important;
    height: auto;
}

.rd{
    
    position: relative;
    
}
.cv{
    margin-left: 1%;
    position: absolute;
    z-index: 5;
    margin-top: auto;
    margin-top: 3%;
   
   
    
}
/*fin
 modification sur l'input */

/* positionnements des composants et marges */
.content-mess{
    height: 70vh;
    flex-direction: column;
    overflow-y: scroll;
 
    justify-content: flex-end;
   
}
.space-align-block {
    flex: none;  
  }
.space-align-block .mock-block {
    display: inline-block;
   
  }

.conten{
  
    flex-flow: column wrap;
    width: 100%;
   
    
}
.head{
  
    justify-content: space-between;
    border-bottom: var(--border);

    color: #666;
}
.position{
    margin-top: 2%;
   position: relative;
   display: inline-block;
   overflow: visible;
   
}
.p-mess{
    position: relative;
}
.pos{
  
    margin-top: 10%!important; 
}
.pp{
    margin: auto!important;
}
.text{
    font-weight: bold;
}
.mh{
    
    box-shadow: 0px 2px 2px  rgb(228, 226, 226)!important;
    margin-bottom: 5%!important;
}
.mg{
padding: 15px!important;
   
}
.mgb{
    margin: auto;
    max-width: 800px;
}
.mgl{
    margin-left: 10px!important;
}
.role{
    justify-content: flex-start!important;
}
.sp{
    margin-left: 10px!important;
}
.sh{
    display: none;
}
.crp{
    cursor: pointer;
}
.s-ro{
    width: 90%!important;
}
.cf{
    overflow: inherit!important;
}
.bts{
    color: rgb(212, 21, 21)!important;
    background-color: rgb(241, 239, 239)!important;
}
.btn-danger{
    background-color: rgb(245, 68, 68)!important;
    color: white!important;
}
.btn-danger:hover{
    color:  rgb(240, 217, 217)!important;
}
.pd{
    padding: 5%!important;
}
.hov-list{
    cursor: pointer;
}
.hov-list:hover{
    color: blue;
}
.pos-i{
    width: 100%!important;
    height: auto!important;

}
/* tabs modification d'éléments */
.altab .ant-tabs-left  .ant-tabs-nav  .altab .ant-tabs-nav{
    justify-content: flex-start;
}
.ant-tabs-left  .altab {
    justify-content: flex-start;
    padding-left: 8px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wr {
    justify-content: flex-start;
    padding-left: 8px;
}
.altab > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
    position: relative; 
    display: flex; 
    flex: none;
    align-items: flex-start; 
}
.abd > .altab .ant-tabs-nav .ant-tabs-tab {
    margin: 0 0 6px 0 !important;
    padding: 8px 12px !important;
    border-radius: 14px 0 0 14px;
}